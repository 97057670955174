.hoverBtn {
  cursor: pointer;
  font-family: "PrimaryFont";
  font-size: 1.1rem;
  position: relative;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  padding-inline:1rem;
  padding-block: 0.5rem;
  border:none;
  color:var(--textSecondary);
  background-color: white;
  display: inline-block;
  width: 100%;
  border:1px solid var(--textSecondary);
}

.hoverBtn::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  background-color: var(--textSecondary);
  color:white;
  transition: 0.5s ease-in-out;
  z-index: -1;
}

.hoverBtn:hover::before {
  width: 100%;
  right: 0%;
}

.hoverBtn:hover {
  background-color:var(--textSecondary);
  color: white;
}

.hoverBtn:active {
  background-color:  rgb(75, 21, 101)
}
.hoverBtn:active::before {
  background-color:  rgb(75, 21, 101)
}

.hoverBtn:focus {
  outline: none
}
.pressBtn {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.pressBtn:hover,
.pressBtn:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.pressBtn:hover {
  transform: translateY(-1px);
}

.pressBtn:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
