.container{
    width: 55%;
    margin-inline:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    border-radius: 1rem;
    padding:1rem;
}
.inputField{
    margin-bottom:15px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.inputField label {
  font-family: "PrimaryFont";
}
.heading{
    color:var(--textSecondary);
    font-size:2.5rem;
    font-family: "SecondaryFont";
    font-weight: bold;
    margin-block:15px;
}
.text {
    color:var(--textPrimary);
    font-family: "PrimaryFont";
    font-weight: bold;
}
.loginBtn{
    font-family: "PrimaryFont";
  font-size:1.2rem;
   display: block;
   width: 70%;
  border:1px solid var(--textSecondary);
  border-radius: 0;
  color: var(--textSecondary);
  overflow: hidden;
  z-index: 1;
  padding-inline:1rem;
  padding-block:0.6rem;
  background: transparent;
  transition: color 150ms ease-in-out;
  position: relative;
}
  
  .loginBtn:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: var(--textSecondary);
    z-index: -1;
    transition: width 150ms ease-in-out;
  }
  
  .loginBtn:hover{
    color:white;
  }
  .loginBtn:hover::after{
    width: 100%;;
  }
  .error{
    color:red;
  }