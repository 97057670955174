.card {
  /* position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  margin: -150px;
  float: left; */
  display: block;
}
.imgContainer{
  width: 90%;
  object-fit: contain;
  margin-inline: auto;
}
.img {
  width: 100%;
  height: 200px;
}
.content {
  width:350px;
  height:500px;/*without this everything breaks*/
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  transition: transform 3s;
  transform-style: preserve-3d;
  /* margin:10px; */
  font-family: "PrimaryFont";
  border:1px solid black;
}
.heading{
	/* font-family:"SecondaryFont"; */
  font-weight: bolder;
}

.card:hover .content {
  transform: rotateY( 180deg ) ;
  transition: transform 1s;
}

.front,
.back {
  position: absolute;
  height: 100%;
  width: 100%;
  /* width:fit-content; */
  background: white;
  border-radius: 5px;
  backface-visibility: hidden;
}

.back {
  background: white;
  border-radius: 5px;
  transform: rotateY( 180deg );
  padding:1rem;
}
.viewBtn{
    font-family: "PrimaryFont";
   display: block;
   width: 70%;
  border:1px solid var(--textSecondary);
  border-radius: 0;
  color: var(--textSecondary);
  overflow: hidden;
  z-index: 1;
  padding-inline:1rem;
  padding-block:0.6rem;
  background: transparent;
  transition: color 150ms ease-in-out;
  position: relative;
}
  
  .viewBtn:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: var(--textSecondary);
    z-index: -1;
    transition: width 150ms ease-in-out;
  }
  
  .viewBtn:hover{
    color:white;
  }
  .viewBtn:hover::after{
    width: 100%;;
  }
  .courseDetails{
	padding:1rem;
  }
  .courseCard{
    width: 250px;
    font-family: "PrimaryFont";
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .courseCard h3 {
    font-weight: bolder;
  }
  .courseImg >img {
    /* width: 250px; */
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .courseContent > *{
    margin-block: 2px;
    margin-left: 10px;
  }
  .courseContent p {
    font-weight: 200;
  }
  .price {
    font-family: "SecondaryFont";
    font-size: 1.2rem;
    color: var(--textPrimary);
    font-weight: 600;
  }
  .tooltip {
    background-color: white;
    font-family: "PrimaryFont";
    font-size:1rem;
  }
  .tooltip > * {
    margin-bottom: 15px;
  }
  .courseContent li {
    display: flex;
    align-items: flex-start;
    margin-top:5px;
  }