.btnPushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btnShadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.btnEdge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(240deg 100% 16%) 0%,
    hsl(240deg 100% 32%) 8%,
    hsl(240deg 100% 32%) 92%,
    hsl(240deg 100% 16%) 100%
  );
  /* background:linear-gradient(
    to left,
    hsl(240, 89%, 11%) 0%,
    rgb(21, 21, 166) 8%,
    rgb(21, 21, 166) 92%,
    hsl(240, 89%, 11%) 100%,
  ); */
}

.btnFront {
  display: block;
  position: relative;
  padding: 12px 20px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  background: var(--dark);
  will-change: transform;
  transform: translateY(-4px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

@media (min-width: 768px) {
  .btnFront {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.btnPushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.btnPushable:hover .btnFront {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.btnPushable:active .btnFront {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.btnPushable:hover .btnShadow {
  transform: translateY(4px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.btnPushable:active .btnShadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.btnPushable:focus:not(:focus-visible) {
  outline: none;
}
.componentContainer{
    background-color: rgb(240,240,240,0.7);
    display: flex;
    flex-direction: column;
    padding-inline:3rem;
}
.btnContainer {
    width: 80%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}