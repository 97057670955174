.flexContainer {
    display: flex;
    flex-direction: row;
    margin:0px;
    background-color: rgb(235,235,235);
}
.header{
    background-color: var(--textPrimary);
    color:white;
    font-family:"PrimaryFont";
    padding:2.5rem;
}
.header h2 {
    font-size:3rem;
    margin-block:10px;
}
.header p {
    margin-block:5px;
}
.details {
    /* display: flex; */
    /* flex-direction: row; */
}
.details > * {
    /* margin-left:10px; */
}
.filler{
    height:100px;
    background-color: var(--textPrimary);
}
 .courseCard{ 
    padding:20px;
    position: sticky;
    top:0;
    font-family: "PrimaryFont";
    background-color: rgba(245, 245, 245);
    border:2px solid white;
} 
.courseCard img {
    width: 100%;
}
.listContainer {
    padding:1rem;
    font-family: "PrimaryFont";
    font-size:1.2rem;
    border:1px solid black;
    margin-top:2rem;
    margin-inline:1rem;
    background-color: white;
    border-radius: 10px;
}
.leftCol{
    flex: 1 1 70%;
}
.rightCol {
    flex: 1 1 30%;
}
.listContainer ul {
    list-style-type:none;
    margin-inline:20px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 40vh;
}
.listContainer li {
    display: inline-block;
    margin:10px 10px 10px 0;
}
/*styling the input tags*/
input[id="courseTitle"] , input[id="courseDescription"] , input[id="instructorName"],input[id="courseLanguage"]{
    border:2px solid white;
    font-family: "PrimaryFont";
    color:white;
    background-color: var(--textPrimary);
    font-size:1rem;
}
.header input:focus {
    border:2px solid var(--textSecondary);
}
input[id="courseTitle"]{
    font-size:2.5rem;
}
input[id="courseTitle"]:focus{
    border:2px solid var(--textSecondary);
    color:white;
}
input[id="courseDescription"] {
    border:2px solid white;
    font-size:1rem;
    display: block;
    width:70%;
    min-height: 20vh;;
}
.listContainer input {
    border:2px solid var(--textSecondary);
    display: inline-block;
    width: 70%;
    font-size:1.2rem;
    height:40px;
    color:var(--textPrimary);
    border-radius:10px;
}
 ul {
list-style-type: none;
padding:0px;
margin:0px;
}
.courseCard li {
    display: flex;
    align-items: center;
}
.courseCard input {
    flex: 1 1 70%;
    font-size:1rem;
    display: inline-block;
    margin-block:10px;
    border-radius: 5px;
    border:1px solid black;
    min-height: 30px;
}
.btnContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row ;
    margin-block:1rem;
    padding-inline:20px;
}
.registerBtn,.uploadBtn {
  appearance: none;
  display: inline-block;
  background-color: white;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  font-family: "PrimaryFont";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  outline: none;
  padding: 16px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.registerBtn:disabled,.uploadBtn:disabled {
  pointer-events: none;
}

.registerBtn:hover,.uploadBtn:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.registerBtn:active,.uploadBtn:active {
  box-shadow: none;
  transform: translateY(0);
}