  Link{
    text-decoration: none;
  }
  .lessonContainer {
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        font-family: "PrimaryFont";
        font-weight: 600;
        background-color: #f7f9fa;
        border:1px solid #edf0f2;
    }
    .lessonComponent {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding-inline: 1rem;
        font-size:1.2rem;
        transition: all 0.5s ease-in;
        /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
        border-bottom: 1px solid #edf0f2;
    }
    .lessonComponent * {
        text-decoration: none;
    }
    .lessonComponent:hover{
        cursor: pointer;
        /* font-size:1.35rem; */
    }
    .completed{
        background-color: rgba(0, 223, 0,0.3);
        padding:1rem;
    }
    .pending{
        background-color: rgba(0, 0, 255, 0.626);
        padding:1rem;
    }