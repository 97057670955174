h4 {

}
.row 
input,
.row 
input[type="radio"] + label,
.row
input[type="checkbox"] + label:before,
.row
select option,
.row
textarea,
select {
  width: 100%;
  padding: 1em;
  line-height: 1.4;
  background-color: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
textarea {
    margin-bottom: 10px;
    width: 100%;
}
.row 
input:focus,textarea:focus {
  outline: 0;
  border-color: var(--textSecondary);
}
.row
input:focus + .inputIcon i {
  color:var(--textSecondary)
}
.row
input:focus + .inputIcon:after {
  border-right-color: var(--textSecondary);
}
input[type="radio"] {
  display: none;
}
.inputGroup {
  margin-bottom: 1em;
  zoom: 1;
}
.inputGroup:before,
.inputGroup:after {
  content: "";
  display: table;
}
.inputGroup:after {
  clear: both;
}
.inputGroupIcon {
  position: relative;
}
.inputGroupIcon input {
  padding-left: 4.4em;
}
.inputGroupIcon .inputIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4em;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputGroupIcon .inputIcon:after {
  position: absolute;
  top: 0.6em;
  bottom: 0.6em;
  left: 3.4em;
  display: block;
  border-right: 1px solid #e5e5e5;
  content: "";
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.inputGroupIcon .inputIcon i {
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.container {
  /* max-width: 38em; */
  width: 50%;
  padding: 1em 3em 2em 3em;
  margin: 2rem auto;
  background-color: #fff;
  border-radius: 4.2px;
  box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2);
}
.firstRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.rightCol{
    flex: 1 1 60%;
    padding-left:10px;
}
.title{
    font-family: "SecondaryFont";
    color: var(--textSecondary);
    font-size: 2rem;
}
.row {
  zoom: 1;
}
.row:before,
.row:after {
  content: "";
  display: table;
}
.row:after {
  clear: both;
}
@media only screen and (max-width: 540px) {
  .col-half {
    width: 100%;
    padding-right: 0;
  }
}
