.wrapper{
    background-color: rgb(240,240,240,0.7);
    padding-left:1rem;
}
.wrapper h2{
    font-family: "PrimaryFont";
    font-size: 2.3rem;
}
.reviewContainer {
    margin:1rem;
    gap: 20px;
    width: 80%;
    margin-inline: auto;
    max-height: 1000px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.reviewCard {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-family: "PrimaryFont";
    font-size:1rem;
    border:1px solid var(--textPrimary);    
    line-height: 1rem;
    margin-bottom: 10px;
    background-color: white;
}