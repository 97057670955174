
@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

.spinner {
  transform-origin: center;
  animation-name: animation;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier;
  animation-iteration-count: infinite;
}
.loader {
  animation: spin 1s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: #09f;
  border-top-color: #09f;
  border-right-color: rgb(132, 19, 207);
  border-top-color: rgb(132, 19, 207);
  box-sizing: border-box;
  height: 20vmin;
  left: calc(50% - 10vmin);
  position: fixed;
  top: calc(50% - 10vmin);
  width: 20vmin;
  z-index: 1;
}
.loader::before {
  animation: spin 2s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: #3cf;
  border-top-color: #3cf;
  border-right-color: var(--textSecondary);
  border-top-color: var(--textSecondary);
  box-sizing: border-box;
  content: "";
  height: 16vmin;
  left: 0;
  position: absolute;
  top: 0;
  width: 16vmin;
}
.loader::after {
  animation: spin 3s infinite linear;
  border: solid 2vmin transparent;
  border-radius: 50%;
  border-right-color: #6ff;
  border-top-color: #6ff;
  border-right-color: rgb(191, 102, 255);
  border-top-color: rgb(191, 102, 255);
  box-sizing: border-box;
  content: "";
  height: 12vmin;
  left: 2vmin;
  position: absolute;
  top: 2vmin;
  width: 12vmin;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation:ldsDualRing 1.2s linear infinite;
}
@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

