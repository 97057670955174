.formContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    width: 70%;
    padding:1rem;
    margin-inline: auto;
    margin-block:1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-family: "PrimaryFont";
    font-size:1.3rem;
    color:var(--textPrimary);
}
.row{
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
align-items: center;
    margin-block:5px;
    padding:10px 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.row label{
    flex: 1 1 60%;
}
input {
    flex: 1 1 40%;
    display: inline-block;
    font-size: 1rem;
}
.registerBtn,.uploadBtn {
  appearance: none;
  display: inline-block;
  background-color: white;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  font-family: "PrimaryFont";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  outline: none;
  padding: 16px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.registerBtn:disabled,.uploadBtn:disabled {
  pointer-events: none;
}

.registerBtn:hover,.uploadBtn:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.registerBtn:active,.uploadBtn:active {
  box-shadow: none;
  transform: translateY(0);
}