.bodyContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    overflow-y: hidden;
}
.leftCol {
    background: linear-gradient(45deg , rgb(244, 210, 250),rgb(244, 210, 250),rgb(244, 210, 250),rgb(239, 174, 255));
    display: flex;
    align-items: center;
    justify-content: center;
}
.rightCol {
    display: flex;
    align-items: center;
    justify-content: center;
}
.instructorContents , .studentContents{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "PrimaryFont";
}
/* CSS */
.filledBtn {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--textSecondary);
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.filledBtn:hover,
.filledBtn:focus {
  background-color: var(--textSecondary);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.filledBtn:hover {
  transform: translateY(-1px);
}

.filledBtn:active {
  background-color: var(--textSecondary);
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}
.outlinedBtn {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.outlinedBtn:hover,
.outlinedBtn:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.outlinedBtn:hover {
  transform: translateY(-1px);
}

.outlinedBtn:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}